const PrivacyPolicy = () => {
  return (
    <>
      <div className="container pb-16 lg:pb-28">
        <div className="text-body-2 text-wagelink-dark mt-2">
          <p className="mb-3">
            This is the Privacy Policy (“Privacy Policy“) that governs how we,
            WageLink Inc. (collectively “WageLink”, “we”, “our”, or “us”), use
            personal information that we collect, receive and store about
            individuals in connection with the use of our On-Demand Pay (“ODP”)
            websites, mobile apps, and features, programs and partnerships which
            we offer in connection with the WageLink EWA platform, and any other
            offerings for which another privacy policy does not govern
            (collectively, the “Services”). We have implemented this Privacy
            Policy because your privacy, and the privacy of other users, is
            important to us. This Privacy Policy explains our information
            practices and the choices you can make about the way your personal
            information is collected, used, and shared in connection with our
            Services, and in accordance with our Consumer Privacy Notice.
          </p>
          <p className="mb-3">
            Unless we link to a different policy or state otherwise, this
            Privacy Policy applies when you visit or use our Services.
          </p>
          <p className="mb-6 lg:mb-8">Hi, We’re WageLink!</p>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">1. About WageLink</p>
            <p>
              WageLink is the leading fintech provider that enables employees to
              harness the power of their pay and provides innovative financial
              products and solutions to consumers.
            </p>
          </div>
          <p className="font-bold mb-4 text-2xl">
            Personal Information Collection, Use, Disclosure and Anonymization
          </p>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">2. Information Collected</p>
            <p>
              We, our service providers, and our third-party non-affiliates
              receive and/or collect personal information from or about you in
              the following ways (we also provide examples of how we may use the
              data):
            </p>
            <p className="font-semibold">2.1 Personal Information</p>
            <p>
              We collect personal information from you, your bank, your
              employer, and the third-party non-affiliate to whom you provide
              the information used to provide our Services. The personal
              information categories may include:
            </p>
            <ul className="ml-12 list-disc">
              <li>
                Identifiers, such as your first and last name and email address;
              </li>
              <li>
                Government-issued identification cards or the data they reflect,
                such as your driver’s license or state ID card;
              </li>
              <li>
                Employment or financial-related information, such as pay
                information and information relating to the account to which the
                pay is directed;
              </li>
              <li>
                Geolocation data, internet or network activity, such as general
                location or IP address for security and data validation
                purposes;
              </li>
              <li>
                User interface information on our site or in our mobile
                application, such as keystrokes, mouse clicks, page flips, and
                what you viewed to provide faster and better user support and
                aid our engineering team in solving bugs and improving user
                experience;
              </li>
              <li>
                Communications with WageLink via chat or phone call, to provide
                customer service;
              </li>
              <li>
                Other technical information including your browser type, service
                provider, or operating system; and
              </li>
              <li>
                Login credentials for your WageLink account. The purposes for
                use of personal information within these categories are included
                below.
              </li>
            </ul>
            <p className="font-semibold">2.2 Account</p>
            <p>
              In order to utilize our Services, you are required to create an
              account (‘Account’). We may offer different options to create an
              Account, including:
            </p>
            <ul className="ml-12 list-disc">
              <li>
                <span className="font-semibold">Online Registration Form:</span>{" "}
                You may choose to complete and submit an online registration
                form. As part of the registration process, you may be asked to
                provide us with an email and mailing address, mobile phone
                number, date of birth, proof of identity documentation, and a
                password that you will use to access your Account, as well as
                other information that may be required to open your Account.
              </li>
              <li>
                <span className="font-semibold">Third-Party Accounts:</span> You
                may choose to create (or supplement) an Account by clicking on a
                ‘connect,’ ‘sign on,’ or an equivalent button that we may
                display on the site for a designated account that you have with
                a third-party website or service (each a ‘Third-Party Account’).
                Doing so will enable you to link your WageLink Account and your
                Third-Party Account. If you choose this option, then we will
                obtain certain information, including personal information, from
                your Third-Party Account. The information that we obtain from
                your Third-Party Account is information that you have chosen to
                make available to us as part of your use of your Third-Party
                Account, and is typically identified in the privacy policy of
                your Third-Party Account provider. You acknowledge the
                Third-Party Account is governed by the terms of use and privacy
                policy of that account provider. Please note that to use this
                option, you will need to have, and may need to be signed-in to,
                an existing Third-Party Account.
              </li>
            </ul>
            <p>
              Regardless of which method you choose to register your Account, we
              may send an email or text message to your nominated email address,
              mobile phone number, or to the email address that you have
              designated in your Third-Party Account to instruct you how to
              confirm your registration.
            </p>
            <p className="font-semibold">2.3 Your Interactions with Us</p>
            <ul className="ml-12 list-disc">
              <li>
                <span className="font-semibold">Contact Us Information:</span>{" "}
                If you send us a ‘Contact Us’ request, whether by calling our
                customer service number, submitting an online form, emailing a
                WageLink email address, text messaging a company number, or
                interacting with the chat function that we make available on the
                website, you may be required to provide us with certain
                information such as your name, phone number, and email address.
              </li>
              <li>
                <span className="font-semibold">
                  Third Party Notice and Consent:
                </span>{" "}
                When you interact with us through these channels, WageLink and
                our vendors may have access to the communications or your web
                experience. These are recorded for purposes including providing
                you customer service and improving consumer interactions with
                WageLink. Use of these channels reflects your acknowledgment and
                affirmative consent to WageLink and our vendors having access to
                these communications.
              </li>
            </ul>
            <p className="font-semibold">2.4 Log Files</p>
            <p>
              We may make use of log files. The information inside the log files
              includes internet protocol (IP) addresses, type of browser,
              Internet Service Provider (ISP), date/time stamp, referring/exit
              pages, clicked pages and any other information your browser may
              send to us. We may use such information to analyze trends,
              administer our Services, track users’ movement around our
              Services, and gather user categorical data.
            </p>
            <p className="font-semibold">
              2.5 Cookies and Other Tracking Technologies
            </p>
            <p>
              Our website and mobile app may utilize cookies and other tracking
              technologies in order for us to provide or advertise our Services,
              and to enable, optimize, and analyze operation of our Services. A
              cookie is a small text file that may be used, for example, to
              collect information about website and Service activity. Certain
              cookies and other technologies may serve to recall information,
              such as an IP address, previously indicated by a user.
            </p>
            <ul className="ml-12 list-disc">
              <li>
                <span className="font-semibold">Browser Control:</span> Most
                browsers allow you to control cookies, including whether or not
                to accept them and how to remove them. You may set most browsers
                to notify you if you receive a cookie, or you may choose to
                block cookies with your browser.
              </li>
              <li>
                <span className="font-semibold">Website Control:</span> You may
                also set your cookie preferences on our website. If you are not
                logged in or your IP address is blocked, you may have to re-set
                your preferences on a subsequent visit when you are logged in or
                your address is unblocked. You may also have to do so on each
                device from which you access our Services.
              </li>
            </ul>
            <p className="font-semibold">2.6 Data Providers</p>
            <p>
              We may obtain information about you, such as mailing address,
              gender, age, marital status, ethnicity, and information about your
              home and automobile from third-party data providers. We may use
              this information for marketing purposes and research purposes.
            </p>
            <ul className="ml-12 list-disc">
              <li>
                <span className="font-semibold">Marketing:</span> We may use
                this info to market our products and services to you.
              </li>
              <li>
                <span className="font-semibold">Research:</span> We may use this
                info to provide aggregated, anonymized insights to third-party
                entities for the purpose of determining the impact of Services
                on user financial wellness.
              </li>
            </ul>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">3. Purposes/Use of Personal Information</p>
            <p>
              If you submit or we collect personal information through the
              Services, then such personal information may be used by us in the
              following ways:
            </p>
            <p>
              3.1 We may use or share with our subsidiaries, service providers,
              contractors, third-party nonaffiliates, or employer partners these
              categories of personal information collected for purposes of:
            </p>
            <ul className="ml-12 list-disc">
              <li>
                Data security and integrity, including fraud or illegal activity
                prevention and detection;
              </li>
              <li>System maintenance and repair;</li>
              <li>Non-personalized advertising;</li>
              <li>Account maintenance or service;</li>
              <li>Marketing or advertising;</li>
              <li>Customer service;</li>
              <li>Processing or fulfilling transactions;</li>
              <li>Verifying customer information;</li>
              <li>Processing payments;</li>
              <li>
                Research and development of new products or improvement of
                existing products; or
              </li>
              <li>Service quality assurance or enhancement</li>
            </ul>
            <p>
              3.2 We may use your personal information to provide and improve
              our Services, to create insights about our users, to contact you
              in connection with the Services and certain programs, features, or
              offerings that you may have registered for, to advertise our
              Services, and to identify and authenticate your access to the
              parts of the Services that you are authorized to access.
            </p>
            <p>
              3.3 We may use your designated email address or mobile phone
              number to: (i) send you updates or news regarding the Services;
              and/or (ii) respond to a Contact Us or administrative request (for
              example, to change your password).
            </p>
            <p>
              3.4 We may transfer your personal information to our subsidiaries,
              affiliates, vendors, or employer clients to provide our Services.
              When we share personal information with our service providers and
              non-affiliated companies that perform support services for us, the
              information may be used for improvement of fraud analysis,
              identity verification, risk management services for which we
              engage them, or other services for which we engage them.
            </p>
            <p>
              3.5 We may share your name and contact information with other
              companies that offer products or services that may be of interest
              to you.
            </p>
            <p>
              3.6 We, our service providers, and our third-party advertising
              partners may collect and use your personal information for
              marketing and advertising purposes:
            </p>
            <ul className="ml-12 list-disc">
              <li>
                <span className="font-semibold">Direct marketing:</span> We may
                send you direct marketing communications as permitted by law,
                including by email. You may opt-out of our marketing
                communications as described in the Opting Out section below.
              </li>
              <li>
                <span className="font-semibold">
                  Interest-based advertising:
                </span>{" "}
                We may engage third-party advertising companies and social media
                companies to display ads promoting our and other services across
                the web. These companies may use cookies and similar
                technologies to collect information about your interaction
                (including the data described above) over time across the
                Internet and use that information to serve online ads that they
                think will interest our business clients or prospects. You may
                opt-out of this interest-based advertising as described further
                in the Opting Out section below, but you may receive less
                relevant ads instead.
              </li>
            </ul>
            <p>
              3.7 We may use your personal information to create de-identified
              information. We may use this de-identified information for any
              purpose and without restriction. We may share this de-identified
              data with our partners, service providers, and third parties to
              create information products for research purposes.
            </p>
            <p>
              3.8 We use your personal information to enforce our Privacy
              Policy, Terms of Service, and other terms and conditions, to
              protect our rights and the rights of others, to assist law
              enforcement or government agencies, to prosecute or defend a
              lawsuit, and to comply with applicable laws, regulations, and
              legal processes.
            </p>
            <p>
              3.9 We may also disclose personal information to third parties
              with your consent. We may obtain your consent in writing, online,
              through “click-through” agreements, when you accept the terms of
              this Privacy Policy, orally on the phone, or by other means.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">4. Anonymized Data</p>
            <p>
              We may use, aggregate, anonymize, de-identify, or disclose any
              data (including personal information) we collect, in an anonymized
              format for business analysis, business insights, marketing,
              analytics, benchmarking, and research. This Privacy Policy does
              not limit our use or disclosure of anonymized data, which may be
              shared with third parties.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">5. Disclosure of Personal Information</p>
            <p>
              We may disclose personal information to third parties for a
              business purpose, as described above. We may also disclose
              personal information to others, including third parties, when you
              expressly consent to or request such sharing.
            </p>
            <p>
              5.1 We may disclose personal information to government,
              regulatory, and law enforcement agencies as required or authorized
              by law, or to third parties in connection with any proposed or
              actual reorganization, merger, sale, joint venture, assignment,
              transfer, or other disposition of all or any portion of our
              business, assets, or stock (including in connection with any
              bankruptcy or similar proceedings). In the event of any such
              reorganization, merger, sale, joint venture, assignment, transfer,
              or other disposition of our business, assets, or stock, you will
              receive notice if your personal information is transferred, or
              becomes subject to a different privacy policy
            </p>
            <p>
              5.2 We do not sell personal information. We may share certain
              personal information with third parties for the purposes of
              operating our business, delivering, analyzing, improving,
              securing, and customizing our services, and providing personalized
              advertising and marketing. The categories of information we share
              include identifiers, demographic data, commercial data, online
              activity data, and inferences. We may also share de-identified or
              aggregated data with third parties to help deliver, develop,
              analyze, improve, secure, and customize our services.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">
              6. Access, Correction, and Deletion of Your Personal Information
            </p>
            <p>
              You may request access to your personal information in our
              possession or control. If you have a WageLink Account, you can
              also view and update your personal information by logging into
              your WageLink Account. You may request the correction of
              inaccurate information or the deletion of your personal
              information in our possession or control. Please note that some
              personal information may be exempt from such requests, or we may
              decline your request for deletion if retention of the personal
              information is necessary for us or our service providers to
              provide the services you have requested or maintain your account.
            </p>
            <p>
              To request access to, correction of, or deletion of your personal
              information, please contact us at{" "}
              <span>
                <a href="mailto:support@wagelink.io" className="underline">
                  support@wagelink.io
                </a>
                .
              </span>
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">7. Opting Out</p>
            <ul className="list-disc ml-12">
              <li>
                <span className="font-semibold">
                  Opting Out of Communications
                </span>{" "}
                You may opt out of receiving marketing communications from us by
                following the instructions contained in the messages you
                receive, or by contacting us as described below. If you opt out
                of receiving marketing communications from us, we may still send
                you administrative messages or other information about your
                Account or the Services.
              </li>
              <li>
                <span className="font-semibold">
                  Opting Out of Interest-Based Advertising
                </span>{" "}
                You can opt out of interest-based advertising by following the
                instructions provided by the Digital Advertising Alliance (DAA)
                and Network Advertising Initiative (NAI). Note that opting out
                of interest-based advertising does not mean you will not see any
                ads, but they may be less relevant to you.
              </li>
            </ul>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">8. Third Party Services and Websites</p>
            <p>
              Our Services may contain links to third-party services and
              websites. We are not responsible for the privacy practices or
              content of such services or websites, and this Privacy Policy does
              not apply to them. We encourage you to read the privacy policies
              of those third-party services and websites to learn about their
              privacy practices.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">9. Children's Privacy</p>
            <p>
              Our Services are not intended for use by children under the age of
              13, and we do not knowingly collect or solicit personal
              information from children under the age of 13. If we learn that we
              have collected personal information from a child under the age of
              13, we will delete that information.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">10. Changes to this Privacy Policy</p>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our privacy practices or the law. We will notify you of
              any material changes to this Privacy Policy by posting the updated
              Privacy Policy on our website or by other means. Your continued
              use of our Services after the effective date of the updated
              Privacy Policy constitutes your acceptance of the updated Privacy
              Policy.
            </p>
          </div>
          <div className="space-y-4">
            <p>Contact us</p>
            <p>
              If you have any questions or concerns about this privacy policy or
              our privacy practices, please contact us at:
            </p>
            <p>
              email:{" "}
              <a href="mailto:support@wagelink.io" className="underline">
                support@wagelink.io
              </a>
            </p>
            <p>helpline: + (310) 601-8444</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
