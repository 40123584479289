import React from "react";

const Terms = () => {
  return (
    <>
      <div className="container pb-16 lg:pb-28">
        <div className="text-body-2 text-wagelink-dark">
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">1. Acceptance of Agreement</p>
            <p>
              You agree to the terms and conditions outlined in this Terms and
              Conditions of use Agreement ("Agreement") with respect to our
              Services. This Agreement constitutes the entire and only agreement
              between us and you, and supersedes all prior or contemporaneous
              agreements, representations, warranties and understandings with
              respect to the Services, the content, goods and services provided
              by or through the Services, and the subject matter of this
              Agreement.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">
              2. Information Collection, Use, and Sharing
            </p>
            <p>
              Whenever you are using the WageLink app or WageLink related
              services, we may collect your data. We collect various types of
              personal information including: your name, age, address, social
              security number, card details (card number, admin number, routing
              number), and company-related information for providing
              payroll-related services. Your personal data will be used for
              providing and improving our Services, identification,
              authentication, service improvement, communication, research, and
              marketing. Since we also have publicly available services, social
              media, and community groups, we recommend you not to provide your
              sensitive information in such publicly exposed areas. There are
              legal and compliance requirements such as AML practices, financial
              audit practices and so on that we should adhere to.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">3. Personal Information We Collect</p>
            <p>We collect the following personal information from users:</p>
            <ul className="list-disc ml-12">
              <li>Name</li>
              <li>Age</li>
              <li>Address</li>
              <li>Social security number</li>
              <li>Company name</li>
              <li>Company address</li>
              <li>Bank account information</li>
              <li>Credit card information</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Date of birth</li>
              <li>IP address</li>
              <li>Device information</li>
              <li>Usage data</li>
              <li>Demographic information</li>
            </ul>
            <p>
              We may also collect other personal information from users, such as
              their employment related information, and card usage.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">4. Use and Disclosure of Information</p>
            <p>
              We don't share your data with other partners unless it is required
              by compliance or legal obligations. While sharing your information
              with partners, we make sure we are sharing the minimum level of
              data and in a way that is as anonymous as possible. We may share
              your information with trusted third-party partners for reasons
              including but not limited to audits, enhancing the Services,
              facilitating product, software and applications development,
              improving our services, conducting customer satisfaction surveys,
              fintech regulation and compliance with our legal obligations,
              policies, and procedures. This may also include compliance with
              relevant laws and regulatory requirements, for anti-money
              laundering practices, and to protect our legal interests and those
              of others.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">5. Security and Confidentiality</p>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures, as well as physical security
              measures to protect against unauthorized access, alteration,
              disclosure or destruction of your personal information, username,
              password, transaction information and data stored in your user
              account.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">6. Intellectual Property</p>
            <p>
              The Services and its original content, features and functionality
              are owned by WageLink and are protected by international
              copyright, trademark, patent, trade secret and other intellectual
              property or proprietary rights laws.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">7. Compliance with Laws</p>
            <p>
              We cooperate with our private partners, government, law
              enforcement officials, service providers to enforce and comply
              with the law. We may disclose any information about you to
              government or law enforcement officials or private parties as we,
              in our sole discretion, believe necessary or appropriate to
              respond to claims and legal process, to protect our property and
              rights or the property and rights of a third party, to protect the
              safety of the public or any person, or to prevent or stop activity
              we may consider to be, or to pose a risk of being, illegal,
              unethical or legally actionable.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">8. Marketing and Promotions</p>
            <p>
              We may use your information to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. We may provide you information about our
              future events, updates, latest developments, security alerts and
              so on. You may opt out of receiving any, or all, of these
              communications from us by deactivating your profile from our
              system.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">9. Modification of Terms</p>
            <p>
              We reserve the right, at our sole discretion, to change, modify or
              otherwise alter these Terms at any time. You must review these
              Terms on a regular basis to keep yourself informed of any changes.
              Your continued use of the Services following the posting of any
              changes to these Terms will signify your acceptance of those
              changes.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">10. Termination</p>
            <p>
              We may terminate or suspend your access to the Services, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if you breach the Terms.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">11. Governing Law</p>
            <p>
              These Terms and your use of the Services are governed by and
              construed in accordance with the laws of the United States, and
              specifically in accordance with the laws of the State of Delaware.
            </p>
          </div>
          <div className="mb-12 lg:mb-20 space-y-4">
            <p className="font-bold">Contact Us</p>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p>
              email:{" "}
              <a href="mailto:support@wagelink.io" className="underline">
                support@wagelink.io
              </a>
            </p>
            <p>phone: + (310) 601-8444</p>
            <p>
              Your use of the Services, including any dispute concerning
              privacy, is subject to these Terms and our Privacy Policy. BY
              USING OUR SERVICES, YOU ARE ACCEPTING THE PRACTICES SET OUT IN
              THESE TERMS AND OUR PRIVACY POLICY.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
