import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import WageLink from "./employers/WageLink";
import WageLinkSecond from "./employers/WageLinkSecond";
import Enquiry from "../enquiry/Enquiry";
import Heading from "./heading/Heading";
import Introduction from "./introduction/Introduction";
import WagelinkPayCard from "./mob-description/WagelinkPayCard";
import SetupWagelink from "./mob-description/SetupWagelink";

const HomePage = () => {
  return (
    <>
      <div className="heading border-b border-wagelink-light-grey">
        <Navbar />
        <Heading />
      </div>
      <Introduction />
      <hr />
      <WageLink />
      <hr />
      <WageLinkSecond />
      <hr />
      <WagelinkPayCard />
      <hr />
      <SetupWagelink />
      <hr />
      <Enquiry />
      <Footer />
    </>
  );
};

export default HomePage;
