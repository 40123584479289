type Props = {
  title: string;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ title, className, ...rest }: Props) => {
  return (
    <button
      className={`bg-wagelink-purple text-white rounded-[50px] w-full text-body whitespace-nowrap ${
        className && className
      }`}
      {...rest}
    >
      {title}
    </button>
  );
};

export default Button;
