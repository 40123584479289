import Enquiry from "../enquiry/Enquiry";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Terms from "./Terms";

const TermsAndConditionsPage = () => {
  return (
    <>
      <div className="relative">
        <div className="terms-and-conditions-background-banner"></div>
        <Navbar />
        <div className="container pt-20 lg:pt-28">
          <h3 className="uppercase text-wagelink-purple text-sub-head-caps">
            WAGELINK TERMS AND CONDITIONS
          </h3>
          <p className="py-12 lg:py-16 text-body-2 text-wagelink-dark">
            Please review these Terms and Conditions ("Terms", "Terms and
            Conditions") carefully. If you do not agree to these Terms, you
            should not use our Services. The term ‘you’ refers to the user or
            viewer of our Services.
          </p>
        </div>
      </div>
      <Terms />
      <hr></hr>
      <Enquiry />
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
