import { IphoneIMG } from "../../../assets/images";
import {
  AccessWahesIcon,
  DebitCardIcon,
  SavingIcon
} from "../../../assets/icons";
import IntroCard from "./IntroCard";

type Props = {};

const Introduction = (props: Props) => {
  const cardData = [
    {
      title: "Access your wages sooner",
      description:
        "Get your wages before payday to manage expenses and avoid bank fees.",
      icon: <AccessWahesIcon className="h-16 w-16" />,
      background: "bg-wagelink-orange/20"
    },
    {
      title: "Use the WageLink Visa PayCard",
      description:
        "Receive and spend your wages at more than 50 million merchants worldwide.",
      icon: <DebitCardIcon className="h-16 w-16" />,
      background: "bg-wagelink-light-grey/40"
    },
    {
      title: "Learn to save",
      description:
        "Keep track of your weekly expenses and manage your finances  using the WageLink mobile app.",
      icon: <SavingIcon className="h-16 w-16" />,
      background: "bg-wagelink-purple/20"
    }
  ];
  return (
    <div className="container flex flex-col lg:flex-row items-start py-14 lg:py-28">
      <div className="w-full lg:w-2/5">
        <div className="max-w-[408px]">
          <img
            className="w-full h-[450px] lg:h-full object-contain lg:object-cover"
            src={IphoneIMG}
            alt="iphone"
          />
        </div>
      </div>
      <div className="w-full mt-8 lg:mt-0 lg:w-3/5">
        <div className="pb-4 lg:pb-8">
          <h1 className="capitalize text-wagelink-purple text-headline-2">
            Introducing WageLink
          </h1>
          <p className="text-wagelink-dark text-sub-head mt-4 md:mt-8">
            Simply direct a percentage of your paycheck to your WageLink
            PayCard, then use the PayCard to make payments or withdraw cash, and
            the WageLink mobile app to keep track of your earning and spending.
          </p>
        </div>
        {Array.isArray(cardData) &&
          cardData?.map((card, index) => {
            return (
              <IntroCard
                key={index}
                title={card.title}
                description={card.description}
                icon={card.icon}
                background={card.background}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Introduction;
