import { BannerIMG } from "../../../assets/images";

type Props = {};

const Heading = (props: Props) => {
  return (
    <>
      <div className="heading-banner flex flex-col xl:flex-row items-center px-4 xl:px-10 mt-16 sm:mt-20 xl:mt-0 space-y-10">
        <div className="w-full xl:w-1/2">
          <div className="max-w-[785px]">
            <h1 className="text-headline-2">
              We know that life’s full of unexpected moments.<br></br>
              <span className="text-wagelink-purple">
                To reduce financial stress, WageLink gives you access to your
                earnings <span className="italic">before </span>payday.
              </span>
            </h1>
          </div>
        </div>
        <div className="w-full xl:w-1/2 self-end">
          <img
            className="object-contain xl:object-cover h-full max-h-[550px] xl:max-h-full w-full max-w-[950px] ml-auto"
            src={BannerIMG}
            alt="bannerImg"
          />
        </div>
      </div>
    </>
  );
};

export default Heading;
