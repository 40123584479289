import React from "react";
import { WagelinkTodayIMG } from "../../../assets/images";

const wagelinkToday = [
  <>
    Download the WageLink App from the{" "}
    <a
      href="https://apps.apple.com/us/app/wagelink/id6461461372"
      className="text-wagelink-blue"
      target="_blank"
      rel="noreferrer"
    >
      Apple App Store
    </a>
  </>,
  "Link your Employee ID",
  "Order your WageLink PayCard within the app",
  "Set your paycheck allocation amount to send to your WageLink PayCard",
  "Track your earnings and spending on-the-go"
];
const SetupWagelink = () => {
  return (
    <div
      id="setup_wagelink"
      className="pt-12 pb-14 lg:pt-[88px] lg:pb-28 container"
    >
      <div className="flex flex-col lg:flex-row gap-y-8">
        <div className="order-2 lg:order-1 w-full lg:w-[55%]">
          <h1 className="text-headline-2 text-wagelink-purple">
            Set up WageLink today
          </h1>
          <div className="px-6 sm:px-10 lg:px-[50px] py-10 sm:py-16 lg:py-[84px] flex flex-col gap-y-8 lg:gap-y-12">
            {wagelinkToday.map((payCard, index) => (
              <div key={index} className="flex items-start gap-x-6 lg:gap-x-10">
                <div className="shrink-0 w-10 h-10 lg:w-16 lg:h-16 grid place-content-center rounded-full border-[3px] border-wagelink-purple text-wagelink-purple text-sub-head-caps font-semibold leading-[34px]">
                  {index + 1}
                </div>
                <div className="text-sub-head text-wagelink-dark">
                  {payCard}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="order-1 lg:order-2 w-full lg:w-[45%] flex flex-col gap-10 justify-between lg:pr-14">
          <div className="max-w-[430px] lg:ml-auto">
            <img className="" src={WagelinkTodayIMG} alt="wagelink-today" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupWagelink;
