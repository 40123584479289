type Props = {
  title: string;
  description: string;
  icon: JSX.Element;
  background: string;
};

const IntroCard = ({ title, description, icon, background }: Props) => {
  return (
    <div
      className={`sm:flex sm:gap-3 space-y-3 sm:space-y-0 items-center sm:items-start justify-between ${background} p-10 rounded-2xl mt-6`}
    >
      <div className="flex flex-col justify-betweenp sm:w-[304px] gap-4">
        <p className="text-wagelink-dark text-body-1B ">{title}</p>
        <div className="flex items-center">{icon}</div>
      </div>
      <p className="max-w-[347px] text-wagelink-dark text-body-2">
        {description}
      </p>
    </div>
  );
};

export default IntroCard;
