import Button from "../common/Button";

type Props = {};

const Enquiry = (props: Props) => {
  return (
    <div id="contact_us" className="pt-12 pb-14 lg:pt-20 lg:pb-28 container">
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full lg:w-[45%]">
          <h1 className="text-headline-2 text-wagelink-purple">
            Have questions?
          </h1>
        </div>
        <div className="w-full lg:w-[55%] lg:pr-16 xl:pr-24 lg:items-end flex flex-col gap-6 lg:gap-8">
          <p className="text-sub-head max-w-[740px] text-wagelink-dark">
            Reach out to the WageLink team to learn more.
          </p>
          <a href="mailto:support@wagelink.io">
            <Button title="Contact Us" className="!w-[232px] py-3" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
