import React from "react";
import { WagelinkPayCardIMG } from "../../../assets/images";
import { CircleCheckIcon } from "../../../assets/icons";

const wagelinkPayCards = [
  "Put aside part of your salary for a savings goal",
  "Set a budget and manage your income and expenses",
  "Track your spending habits over time",
  "Send funds to family members or other loved ones"
];

const WagelinkPayCard = () => {
  return (
    <div className="pt-12 pb-14 lg:pt-[88px] lg:pb-28 container">
      <div className="flex flex-col lg:flex-row gap-y-8">
        <div className="w-full lg:w-[45%] flex flex-col gap-10 justify-between lg:pr-14">
          <div className="max-w-[430px]">
            <img className="" src={WagelinkPayCardIMG} alt="wagelink-paycard" />
          </div>
        </div>
        <div className="w-full lg:w-[55%] space-y-8 lg:space-y-10">
          <h1 className="text-headline-2 text-wagelink-purple mb-8 lg:mb-12">
            Why use the WageLink PayCard?
          </h1>
          <div className="px-6 sm:px-10 lg:px-[50px] py-10 sm:py-16 lg:py-[84px] flex flex-col gap-y-8 lg:gap-y-12 bg-wagelink-purple/20 rounded-xl">
            {wagelinkPayCards.map((payCard, index) => (
              <div key={index} className="flex items-start gap-x-6 lg:gap-x-10">
                <CircleCheckIcon className="w-8 h-8 lg:w-11 lg:h-11 shrink-0" />
                <div className="text-body-1B text-wagelink-dark">{payCard}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WagelinkPayCard;
