import { Employee2IMG } from "../../../assets/images";
import WageLinkCard from "./WageLinkCard";

type Props = {};

const WageLinkSecond = (props: Props) => {
  const data = [
    {
      title: "Get Paid Sooner",
      description: "Draw a portion of your paycheck before payday."
    },
    {
      title: "Use your WageLink PayCard",
      description:
        "To shop online, in store, make automatic payments or withdraw cash"
    },
    {
      title: "Make Mobile Payments",
      description:
        "Connect theWageLink PayCard to Apple Pay®, Google Pay® and other mobile wallets"
    },
    {
      title: "Take Control of your Finances",
      description:
        "Use the WageLink mobile app to manage your earnings and budget your expenses in one place"
    }
  ];
  return (
    <div className="pt-10 pb-14 lg:pt-16 lg:pb-28 container">
      <div className="flex flex-col lg:flex-row gap-y-8 ">
        <div className="w-full lg:w-[45%] flex flex-col gap-10 justify-between lg:pr-14">
          <div className="">
            <h1 className="text-headline-2 text-wagelink-purple mb-16">
              WageLink for Employees
            </h1>
            <h1 className="text-sub-head text-wagelink-dark">
              Have more control over your financial wellness.
            </h1>
          </div>
          <div className="max-w-[550px]">
            <img className="" src={Employee2IMG} alt="employe1" />
          </div>
        </div>
        <div className="w-full lg:w-[55%] grid sm:grid-cols-2 gap-6 lg:gap-9">
          {Array.isArray(data) &&
            data.map((item, index) => {
              return (
                <WageLinkCard
                  key={index}
                  className="bg-wagelink-orange/20"
                  title={item.title}
                  description={item.description}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WageLinkSecond;
