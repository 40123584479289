import { Employee1IMG } from "../../../assets/images";
import WageLinkCard from "./WageLinkCard";

type Props = {};

const WageLink = (props: Props) => {
  const data = [
    {
      title: <>Streamline HR.</>,
      description:
        "Reduce time spent setting up payroll processes and focus on growing your business."
    },
    {
      title: "Pay Your Employees Quicker",
      description: "Eliminate the need to connect to a bank account."
    },
    {
      title: "Offer Earned Wage Access (EWA)",
      description:
        "Improve employee retention, engagement and motivation at work."
    },
    {
      title: <>Say Goodbye to Checks</>,
      description: "Eliminate printing, paper and distribution costs."
    }
  ];
  return (
    <div className="pt-10 pb-14 lg:pt-16 lg:pb-28 container">
      <div className="flex flex-col lg:flex-row gap-y-8">
        <div className="w-full lg:w-[45%] flex flex-col gap-10 justify-between lg:pr-14">
          <div className="">
            <h1 className="text-headline-2 text-wagelink-purple mb-16">
              WageLink for Employers
            </h1>
            <h1 className="text-sub-head text-wagelink-dark">
              Set the tone for your company’s culture.
            </h1>
          </div>
          <div className="max-w-[550px]">
            <img className="" src={Employee1IMG} alt="employe1" />
          </div>
        </div>
        <div className="w-full lg:w-[55%] space-y-8 lg:space-y-10">
          <p className="text-sub-head text-wagelink-dark">
            WageLink is easy for employers to implement and provide as an
            employee benefit.
          </p>
          <div className="grid sm:grid-cols-2 gap-6 lg:gap-9">
            {Array.isArray(data) &&
              data.map((item, index) => {
                return (
                  <WageLinkCard
                    key={index}
                    className="bg-wagelink-light-grey/40"
                    title={item.title}
                    description={item.description}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WageLink;
