import { Route, Routes } from "react-router-dom";
import HomePage from "./components/home";
import PrivacyPolicyPage from "./components/privacy-policy";
import TermsAndConditionsPage from "./components/terms-and-conditions";
import ScrollToTop from "./components/layout/ScrollToTop";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
      </Routes>
    </>
  );
};

export default App;
