import Enquiry from "../enquiry/Enquiry";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import PrivacyPolicy from "./PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className="relative">
        <div className="privacy-policy-background-banner"></div>
        <Navbar />
        <div className="container pt-20 lg:pt-28">
          <h3 className="uppercase text-wagelink-purple text-sub-head-caps">
            Payroll Growth Partners, LLC and WageLink Privacy Policy
          </h3>
          <div className="pt-12 pb-16 lg:pt-16 lg:pb-20 text-body-2 text-wagelink-dark">
            <p className="mb-[18px]">Last Revised Date: August 21, 2024</p>
            <p className="mb-[18px]">
              At WageLink, we’re committed to protecting and respecting your
              privacy as a user.
            </p>
            <p className="">
              We will never sell your sell information and give you complete
              control over your marketing preferences.
            </p>
          </div>
        </div>
        <PrivacyPolicy />
      </div>
      <hr></hr>
      <Enquiry />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
