import { Link, useLocation } from "react-router-dom";
import { DownloadOnAppStoreIMG, WagelinkWhiteIMG } from "../../assets/images";

type Props = {};

const Footer = (props: Props) => {
  const location = useLocation();

  return (
    <div className="footer pt-[140px] pb-6 lg:pt-[200px] lg:pb-11">
      <div className="container">
        <div className="sm:flex space-y-4 sm:space-y-0 items-start justify-between">
          <Link
            to="/"
            className="w-[158px] sm:w-[264px] lg:w-fit h-6 sm:h-10 lg:h-12"
          >
            <img
              src={WagelinkWhiteIMG}
              className="w-[158px] sm:w-[264px] lg:w-fit h-6 sm:h-10 lg:h-12"
              alt="WageLink Footer Logo"
            />{" "}
          </Link>
          <div className="flex flex-col gap-y-2">
            {location.pathname !== "/terms-and-conditions" && (
              <Link
                to="/terms-and-conditions"
                className="text-white hover:text-white/80 text-body"
              >
                Terms and Conditions
              </Link>
            )}

            {location.pathname !== "/privacy-policy" && (
              <Link
                to="/privacy-policy"
                className="text-white hover:text-white/80 text-body"
              >
                Privacy Policy
              </Link>
            )}

            {location.pathname !== "/" && (
              <Link to="/" className="text-white hover:text-white/80 text-body">
                Home
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-2 lg:gap-y-4 mt-5 lg:mt-[30px]">
          <p className="text-[25px] text-wagelink-white">Download the app</p>
          <a
            href="https://apps.apple.com/us/app/wagelink/id6461461372"
            className=""
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={DownloadOnAppStoreIMG}
              alt="Downlaod from app store"
              className="w-full max-w-[170px]"
            />
          </a>
        </div>
        <div className="text-white mt-10 sm:mt-[72px]">
          ©{new Date().getFullYear()} Payroll Growth Partners LLC. All Rights
          Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
