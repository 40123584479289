import { CircleCheckIcon } from "../../../assets/icons";

type Props = {
  title: string | React.ReactNode;
  description: string;
  className?: string;
};

const WageLinkCard = ({ title, description, className }: Props) => {
  return (
    <div className={`rounded-2xl ${className} px-8 pt-8 pb-12`}>
      <div className="space-y-6">
        <div className="space-y-2">
          <CircleCheckIcon className="w-8 h-8 lg:w-11 lg:h-11 shrink-0" />
          <h1 className="text-wagelink-dark text-body-1B">{title}</h1>
        </div>
        <p className="text-wagelink-dark text-body-2">{description}</p>
      </div>
    </div>
  );
};

export default WageLinkCard;
