import { Link, useLocation } from "react-router-dom";
import Button from "../common/Button";
import { WagelinkLogo } from "../../assets/icons";

type Props = {};

const Navbar = (props: Props) => {
  const location = useLocation();

  return (
    <div className="flex items-center gap-4 justify-between px-4 xl:px-10 py-9">
      <div className="shrink-0">
        <Link to="/" className="">
          <WagelinkLogo className="w-[158px] sm:w-[264px] lg:w-fit h-6 sm:h-10 lg:h-[48px]" />
        </Link>
      </div>
      {location.pathname === "/" ? (
        <a
          href="#setup_wagelink"
          className="!max-w-[130px] sm:!max-w-[195px] w-full"
        >
          <Button
            title="Get Started"
            className="!max-w-[130px] sm:!max-w-[195px] !w-full py-3"
          />
        </a>
      ) : (
        <Link to="/" className="!max-w-[130px] sm:!max-w-[195px] w-full">
          <Button
            title="Home"
            className="!max-w-[130px] sm:!max-w-[195px] py-3"
          />
        </Link>
      )}
    </div>
  );
};

export default Navbar;
